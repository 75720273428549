import {Element, Transforms, Editor, Range, Node} from "../../../../../pkg/slate.js";
import {isUrl} from "../../../utils/src/index.js";
import {LINK_TYPE} from "../utils/constants.js";
import {matchElement} from "../utils/queries/index.js";
export const withLinks = (editor) => {
  const {insertData, insertText, isInline, normalizeNode} = editor;
  editor.normalizeNode = ([node, path]) => {
    if (matchElement(LINK_TYPE)(node) && Node.string(node) === "") {
      Transforms.removeNodes(editor, {at: path});
      return;
    }
    normalizeNode([node, path]);
  };
  editor.isInline = (element) => {
    return element.type === LINK_TYPE ? true : isInline(element);
  };
  editor.insertText = (text) => {
    if (editor.selection && Range.isCollapsed(editor.selection)) {
      const [parentNode, parentPath] = Editor.parent(editor, editor.selection);
      if (matchElement("a")(parentNode) && Editor.isEdge(editor, editor.selection.anchor, parentPath)) {
        Transforms.move(editor, {unit: "offset"});
        return insertText(text);
      }
    }
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };
  editor.insertData = (data) => {
    const text = data.getData("text/plain");
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };
  return editor;
};
export const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};
export const getActiveLink = (editor) => {
  const [link] = Editor.nodes(editor, {
    match: (n) => Element.isElement(n) && n.type === LINK_TYPE
  });
  return link;
};
export const isLinkActive = (editor) => {
  try {
    return !!getActiveLink(editor);
  } catch (err) {
    return false;
  }
};
const unwrapLink = (editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) => Element.isElement(n) && n.type === LINK_TYPE
  });
};
export const wrapLink = (editor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }
  const {selection} = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = {
    type: LINK_TYPE,
    href: url,
    children: isCollapsed ? [{text: url}] : []
  };
  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, {split: true});
    Transforms.collapse(editor, {edge: "end"});
  }
};
export const promptForLinkUrl = (editor) => {
  const defaultLink = getActiveLink(editor)?.[0]?.href;
  const url = window.prompt("Enter the URL of the link:", defaultLink);
  if (!url)
    return;
  if (url.startsWith("http://") || url.startsWith("https://")) {
    insertLink(editor, url);
  } else {
    insertLink(editor, "https://" + url);
  }
};
