import {css} from "../../../../../../pkg/@emotion/css.js";
import React, {useEffect, useRef} from "../../../../../../pkg/react.js";
import {ReactEditor, useSlate} from "../../../../../../pkg/slate-react.js";
import {Portal} from "../../components/toolbar/helpers.js";
const mentionSelectWidth = 300;
const mentionChoiceXPadding = 10;
const borderRadius = 5;
export const MentionSelect = ({
  options,
  at,
  selectedOption,
  onClickOption,
  setSelectedOption,
  palette
}) => {
  const ref = useRef();
  const editor = useSlate();
  useEffect(() => {
    if (editor && at && options.length > 0) {
      const el = ref.current;
      const domRange = ReactEditor.toDOMRange(editor, at);
      const rect = domRange.getBoundingClientRect();
      if (el) {
        el.style.top = `${rect.top + window.pageYOffset + 24}px`;
        el.style.left = `${rect.left + window.pageXOffset}px`;
      }
    }
  }, [options.length, editor, at]);
  if (!editor || !at || options.length === 0) {
    return null;
  }
  return /* @__PURE__ */ React.createElement(Portal, null, /* @__PURE__ */ React.createElement("div", {
    ref,
    style: {
      fontFamily: "Lato, sans-serif",
      boxShadow: "2px 2px 6px 3px rgba(0,0,0,0.2)",
      borderRadius: `${borderRadius}px`,
      position: "absolute",
      width: `${mentionSelectWidth}px`,
      overflow: "clip"
    },
    onMouseLeave: () => setSelectedOption(null)
  }, options.map((mentionable, i) => /* @__PURE__ */ React.createElement("div", {
    key: i,
    onMouseEnter: () => setSelectedOption(i),
    onMouseDown: (event) => {
      event.preventDefault();
      onClickOption(editor, mentionable);
    },
    style: {
      color: palette?.blue80 || "black",
      backgroundColor: i === selectedOption ? palette?.blue0 || "purple" : palette?.white || "white",
      cursor: "pointer"
    }
  }, /* @__PURE__ */ React.createElement(MentionLabel, {
    mentionable
  })))));
};
const withEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  width: ${mentionSelectWidth - mentionChoiceXPadding * 2}px;
  text-overflow: ellipsis;
`;
const MentionLabel = ({mentionable}) => {
  return /* @__PURE__ */ React.createElement("div", {
    style: {padding: `6px ${mentionChoiceXPadding}px`}
  }, /* @__PURE__ */ React.createElement("div", {
    style: {fontSize: "16px"},
    className: withEllipsis
  }, mentionable.select.text), /* @__PURE__ */ React.createElement("div", {
    className: withEllipsis,
    style: {fontSize: "13px", marginTop: "3px"}
  }, mentionable.select.subtext));
};
