import { c as createCommonjsModule } from '../common/_commonjsHelpers-8c19dec8.js';
import { r as reactDom } from '../common/index-7c06ae21.js';
import '../common/index-cb274251.js';

var client = createCommonjsModule(function (module, exports) {


{
  var i = reactDom.__SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;
  exports.createRoot = function(c, o) {
    i.usingClientEntryPoint = true;
    try {
      return reactDom.createRoot(c, o);
    } finally {
      i.usingClientEntryPoint = false;
    }
  };
  exports.hydrateRoot = function(c, h, o) {
    i.usingClientEntryPoint = true;
    try {
      return reactDom.hydrateRoot(c, h, o);
    } finally {
      i.usingClientEntryPoint = false;
    }
  };
}
});

export default client;
