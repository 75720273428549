export const firebase = {
  apiKey: "AIzaSyBgRftUUKYmtLzAUvzT0O_jGJWqdV2gXaw",
  authDomain: "witful-sandbox.firebaseapp.com",
  databaseURL: "https://witful-sandbox.firebaseio.com",
  projectId: "witful-sandbox",
  storageBucket: "witful-sandbox.appspot.com",
  messagingSenderId: "413552979391",
  appId: "1:413552979391:web:c0b9c3db12d034a470450a",
  measurementId: "G-E5Q5VTHRZ4",
  clientId: "413552979391-s5t0kofn9p0vnmg60q3i880lkkkq5vet.apps.googleusercontent.com",
  scopes: [
    "https://www.googleapis.com/auth/userinfo.email",
    "https://www.googleapis.com/auth/userinfo.profile",
    "https://www.googleapis.com/auth/calendar.events.readonly"
  ]
};
export const apiGateway = "log-proxy-gw-59zerl4v.uc.gateway.dev";
export const amplitudeKey = "2e523724ebb7fd5208ca322480e8904e";
export const mixpanelKey = "71dd21b7df7de14082acb74400eecd64";
